.project-header {
  padding: 0.3rem 0.8rem 0.1rem 0.3rem;
}

.project-header-content {
  display: flex;
  max-width: 760px;
  margin: auto;
  align-items: center;
}

.header-icon-wrapper svg {
  float: left;
}

.header-icon-wrapper select {
  width: 189px;
  font-size: 1rem;
}

.project-header__icon {
  fill: #99ccff;
}

.header-item {
  flex: 1;
}

.header-title {
  max-width: 66px;
  flex-basis: auto;
  flex-grow: 1;
  font-family: 'lobster';
}

.header-hourly-rate {
  padding-left: 5px;
  font-size: 0.8rem;
}

.header-select {
  display: block;
  font-size: 1rem;
}