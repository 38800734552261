.app-header {
  position: relative;
  display: flex;
  padding: 0.3rem 0.5rem 0.1rem 1rem;
  max-width: 900px;
  margin: auto;
}

.app-logo {
  flex: 1 1;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--blue);
}

.app-logo__black {
  color: var(--black);
  margin-right: 4px;
}

.app-logo__orange {
  color: var(--orange);
  margin-left: 2px;
}

.login-menu img {
  display:inline-block;
  vertical-align:middle;
  width: 20px;
}

.login-menu button {
  padding: 0 0.4rem;
  margin: 0 0.6rem;
  background: var(--yellow);
  border: none;
  border-radius: 2px;
  line-height: 1.4rem;
}

.login-menu a {
}

.beta-tag {
  position: absolute;
  top: 10px;
  font-size: 0.7rem;
  transform: rotate(20deg);
  color: var(--red);
}
.footer {
  display: flex;
  flex-flow: row wrap;
}

.footer > section {
  flex: 0 0 100%;
  text-align: center;
}

.footer-dark-bg {
  padding: 0.8rem 0 0.4rem;
  background-image: linear-gradient(var(--gray), var(--darkgray)); 
  color: var(--white);
}

.footer-headline {
  font-family: 'Lobster', Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-bottom: 1.2rem;
}

.footer-social-links {
  padding-bottom: 1rem;
}

.footer-social-links a { 
  margin: 0 0.8rem;
}

.footer-social-links img {
  width: 22px;
}

.footer-legal a {
  margin: 0 1.5rem;
  color: var(--blue);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.6rem;
  letter-spacing: 1px;
}

.footer-company {
  padding: 0.6rem 0;
  font-size: 0.6rem;
}

.footer-company p {
  margin: 0.2rem 0 0;
  letter-spacing: 1px;
}

.footer-company img {
  width: 55px;
}
.app-menu-wrapper {
  font-family: 'roboto';
  border-bottom: 1px #99ccff solid;
}

.app-menu {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  font-size: 1.1rem;
}

.app-menu li {
  display: inline;
  box-sizing: border-box;
  padding-bottom: 3px;
}

.app-menu li a {
  padding: 0 10px;
  text-decoration: none;
  color: #99ccff;
}

.app-menu li .selected {
  border-bottom: 3px solid #99ccff;
}
.signin {
  text-align: center;
}

.signin a {
  color: var(--black);
  text-decoration: none;
}

.signin .signin-section {
  position: relative;
  padding: 0.5rem 0 0.8rem;
}

.signin-section.signin-yellow-bg {
  position: relative;
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
  padding-bottom: 2rem;
}

.signin-yellow-bg-arrow-down {
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -15px;
  background: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 25px;
  box-shadow: 0 2px #ccc;
  font-family: 'Lobster', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 2.1rem;
  letter-spacing: 2px;
  padding-left: 3px;
}

p {
  padding: 0 1rem;
}

.signin input[type=text],
.signin input[type=password] {
  display: block;
  margin: 1rem auto;
  padding: 0.8rem 1rem;
  background: var(--white);
  border: none;
  text-align: center;
  transition: all 0.2s ease-in;
}

.signin .login--button {
  padding: 0.7rem 0;
  background: var(--yellow);
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 2px;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  min-width: 158px;
}

.signin .login--button:hover {
  background: var(--orange);
  color: var(--white);
  border-color: var(--white);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.signin .provider-login--facebook {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--facebook-blue);
  margin: 1.5rem 0 0.5rem;
}

.signin .provider-login--facebook:hover {
  background: var(--facebook-blue);
}

.signin .provider-login--google:hover {
  background: var(--google-red);
}

.signin .provider-login--github:hover {
  background: var(--github-blue);
}

.signin .provider-login--facebook:hover,
.signin .provider-login--google:hover,
.signin .provider-login--github:hover {
  opacity: 0.8;
}

.signin .provider-login--google {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--google-red);
  margin: 0.5rem 0;
}

.signin .provider-login--github {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--github-blue);
  margin: 0.5rem 0;
}

.signin .password-forget {
  padding: 0.3rem;
  font-size: 0.7rem;
}

.create-account {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}
.add-project form {
  padding: 0.5rem;
}

.add-project input[type="text"],
.add-project input[type="number"] {
  width: 100%;
  background: var(--lightblue);
  border: none;
  padding: 0.5rem 1rem;
}

.add-project-button {
  background: var(--orange);
  border: none; 
  padding: 0.3rem 0.5rem;
}
.spinner {
  text-align: center;
  padding: 1rem 0;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #cef;
  border-color: #cef transparent #cef transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.edit-project {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
}

.edit-project form {
  padding: 0.5rem;
}

.edit-project label {
  line-height: 28px;
}

.edit-project input[type="text"] {
  width: 100%;
  background: var(--lightblue);
  border: none;
  padding: 0.5rem 1rem;
}

.edit-project-button {
  display: block;
  background: var(--orange);
  border: none; 
  padding: 0.3rem 0.5rem;
}
.projects {
  padding: 0.2rem 1rem;
}

.project-list {
  display: flex;
  flex-flow: row wrap;
}

.project-list-header {
  flex: 1 1 100%;
}

.project-list-row-header > div {
  font-weight: bold;
  text-align: center;
  background-color: var(--white)
}

.project-list-row {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  font-size: 0.8rem;
}

.project-list-row:nth-child(odd) {
  background-color: var(--lightyellow)
}

.project-list-item {
  flex: 1 1;
  padding: 0.3rem;
}

.project-list-item--name {
  flex: 2 1;
}

.project-list-item--date {
  font-size: 0.6rem;
  text-align: center;
  line-height: 19px;
}

.project-list-item:last-child {
  text-align: center;
}

.project-list-button {
  background-color: var(--orange);
  border: none;
  padding: 0.2rem 0.5rem;
}
.week {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  margin-bottom: 20px;
  padding: 0.2rem 1rem;
}

.week h2 {
  font-family: 'lobster';
}

.week:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.week-day {
  position: relative;
  display: inline-block;
  text-align: center;
}

.week-day--header {
  padding-right: 5px;
  border-bottom: 1px solid var(--orange);
}

.week-day--summary {
  width: 49px;
  padding: 0.3rem 0.2rem;
  font-size: 22px;
  text-align: center;
  background: var(--lightyellow);
  border: none;
}

.week-day--summary {
  background: none;
}

.week-day--summary__hours {
  font-size: 12px;
  background: var(--lightblue);
}

.week-day input {
  width: 50px;
  margin-right: 5px;
  padding: 0.5rem 0.2rem;
  font-size: 25px;
  text-align: center;
  background: var(--lightyellow);
  border: none;
}

.is-today input::-webkit-input-placeholder {
  color: var(--white);
  background: var(--orange);
}

.is-today input:-ms-input-placeholder {
  color: var(--white);
  background: var(--orange);
}

.is-today input::-ms-input-placeholder {
  color: var(--white);
  background: var(--orange);
}

.is-today input,
.is-today input::placeholder {
  color: var(--white);
  background: var(--orange);
}

.week-day--summary__button {
  border: none;
  background: transparent;
}

.week-day--summary__button:focus {
  outline: none;
}

.week-day--summary__button svg {
  fill: var(--blue);
  transition: fill .4s ease;
}

.week-day--summary__button:hover svg {
  fill: var(--lightblue);
}

/* TEMP styling for contracts */
.red-border {
  border: 1px solid red !important;
}

.blue-border {
  border: 1px solid blue !important;
}
.weekly-summary {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 2px solid var(--orange);
  padding: 0.4rem;
  background-color: var(--white);
  font-weight: 600;
}
.project-header {
  padding: 0.3rem 0.8rem 0.1rem 0.3rem;
}

.project-header-content {
  display: flex;
  max-width: 760px;
  margin: auto;
  align-items: center;
}

.header-icon-wrapper svg {
  float: left;
}

.header-icon-wrapper select {
  width: 189px;
  font-size: 1rem;
}

.project-header__icon {
  fill: #99ccff;
}

.header-item {
  flex: 1 1;
}

.header-title {
  max-width: 66px;
  flex-basis: auto;
  flex-grow: 1;
  font-family: 'lobster';
}

.header-hourly-rate {
  padding-left: 5px;
  font-size: 0.8rem;
}

.header-select {
  display: block;
  font-size: 1rem;
}

.header-time {
  display: flex;
  max-width: 62px;
  font-weight: 700;
  text-align: center;
  padding: 0.3rem;
}

.header-date-item {
  flex: 1 1;
}

.header-date-day {
  font-size: 2.7rem;
  line-height: 2rem;
  font-family: 'lobster';
  padding: 0 0.5rem 0 0;
}

.header-date-month {
  display: block;
  font-family: 'lobster';
  font-size: 0.8rem;
  line-height: 0.7rem;
  letter-spacing: 1px;
}

.header-date-year {
  color: #99ccff;
}

.timesheet {
  padding-bottom: 3rem;
}

.timesheet-header {
  display: flex;
  padding: 0.4rem 1rem;
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
}

.timesheet-title.title {
  flex: 2 1;
  align-items: center;
  line-height: 2.5rem;
  font-size: 1.4rem;
}

.timesheet-date {
  flex: 1 1;
  align-items: right;
}

.timesheet-title svg {
  margin-right: 10px;
} 

.timesheet-date-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.timesheet-date-wrapper__item {
  flex: 1 1;
  padding: 0.5rem 0;
  background: var(--yellow);
  text-align: center;
}

.timesheet-date-wrapper__item:first-child {
  margin-right: 10px;
}

.select-year {
  position: relative;
}

.timesheet-date-wrapper select {
  background: var(--yellow);
  font-size: 1.1rem;
}

.timesheets__icon {
  fill: var(--black);
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -6px;
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  
  border-left: 6px solid var(--black);
}

.arrow-left {
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -6px;
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent; 
  
  border-right: 6px solid var(--black); 
}
.load-data {
  padding: 5rem 0;
  text-align: center;
}
:root {
  --orange: #f3b562;
  --yellow: #f2ebbf;
  --lightyellow: #f6f1d2;
  --lightgray: #a4a291;
  --gray: #363636;
  --darkgray: #252525;
  --red: #f38080;
  --darkblue: #5a9cdd;
  --blue: #9accfe;
  --lightblue: #dbeaf5;
  --green: #40bfa3;
  --white: #fff;
  --black: #272727;
  --facebook-blue: #3b5998;
  --twitter-blue: #0084b4;
  --google-red: #db4a39;
  --github-blue: #4078c0;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-size: 100%;
  color: #363636;
  color: var(--gray);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: calc(100vh - 100px);
  margin: 0;
  background-position: fixed;
  letter-spacing: -1px;
}

a {
  color: #5a9cdd;
  color: var(--darkblue);
}

.header {
  background-image: linear-gradient(#f6f1d2, #f2ebbf);
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
  padding: 1rem;
  text-align: center;
}

.header p {
  font-size: 0.8rem;
}

.content {
  padding: 1rem 2rem;
}

.app-content {
  max-width: 900px;
  margin: 0 auto;
}

.item {
  display: grid;
  justify-content: center;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-size: 35px;
  background-color: #f6f1d2;
  background-color: var(--lightyellow);
}

.item p {
  margin: 0 0 5px 0;
}

.button {
  display: block;
  min-width: 140px;
  margin: 0 0 0.8rem 0;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
}

.button:focus {
  outline: none;
}

/**** Typography ****/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 5px 0;
}

.title {
  font-size: 2rem;
  text-transform: uppercase;
}
