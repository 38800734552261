.projects {
  padding: 0.2rem 1rem;
}

.project-list {
  display: flex;
  flex-flow: row wrap;
}

.project-list-header {
  flex: 1 1 100%;
}

.project-list-row-header > div {
  font-weight: bold;
  text-align: center;
  background-color: var(--white)
}

.project-list-row {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  font-size: 0.8rem;
}

.project-list-row:nth-child(odd) {
  background-color: var(--lightyellow)
}

.project-list-item {
  flex: 1 1;
  padding: 0.3rem;
}

.project-list-item--name {
  flex: 2 1;
}

.project-list-item--date {
  font-size: 0.6rem;
  text-align: center;
  line-height: 19px;
}

.project-list-item:last-child {
  text-align: center;
}

.project-list-button {
  background-color: var(--orange);
  border: none;
  padding: 0.2rem 0.5rem;
}