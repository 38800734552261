.add-project form {
  padding: 0.5rem;
}

.add-project input[type="text"],
.add-project input[type="number"] {
  width: 100%;
  background: var(--lightblue);
  border: none;
  padding: 0.5rem 1rem;
}

.add-project-button {
  background: var(--orange);
  border: none; 
  padding: 0.3rem 0.5rem;
}