:root {
  --orange: #f3b562;
  --yellow: #f2ebbf;
  --lightyellow: #f6f1d2;
  --lightgray: #a4a291;
  --gray: #363636;
  --darkgray: #252525;
  --red: #f38080;
  --darkblue: #5a9cdd;
  --blue: #9accfe;
  --lightblue: #dbeaf5;
  --green: #40bfa3;
  --white: #fff;
  --black: #272727;
  --facebook-blue: #3b5998;
  --twitter-blue: #0084b4;
  --google-red: #db4a39;
  --github-blue: #4078c0;
}

html {
  box-sizing: border-box;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-size: 100%;
  color: #363636;
  color: var(--gray);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: calc(100vh - 100px);
  margin: 0;
  background-position: fixed;
  letter-spacing: -1px;
}

a {
  color: #5a9cdd;
  color: var(--darkblue);
}

.header {
  background-image: linear-gradient(#f6f1d2, #f2ebbf);
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
  padding: 1rem;
  text-align: center;
}

.header p {
  font-size: 0.8rem;
}

.content {
  padding: 1rem 2rem;
}

.app-content {
  max-width: 900px;
  margin: 0 auto;
}

.item {
  display: grid;
  justify-content: center;
  align-items: center;
  border: 5px solid rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-size: 35px;
  background-color: #f6f1d2;
  background-color: var(--lightyellow);
}

.item p {
  margin: 0 0 5px 0;
}

.button {
  display: block;
  min-width: 140px;
  margin: 0 0 0.8rem 0;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
}

.button:focus {
  outline: none;
}

/**** Typography ****/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 5px 0;
}

.title {
  font-size: 2rem;
  text-transform: uppercase;
}