.app-header {
  position: relative;
  display: flex;
  padding: 0.3rem 0.5rem 0.1rem 1rem;
  max-width: 900px;
  margin: auto;
}

.app-logo {
  flex: 1 1;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--blue);
}

.app-logo__black {
  color: var(--black);
  margin-right: 4px;
}

.app-logo__orange {
  color: var(--orange);
  margin-left: 2px;
}

.login-menu img {
  display:inline-block;
  vertical-align:middle;
  width: 20px;
}

.login-menu button {
  padding: 0 0.4rem;
  margin: 0 0.6rem;
  background: var(--yellow);
  border: none;
  border-radius: 2px;
  line-height: 1.4rem;
}

.login-menu a {
}

.beta-tag {
  position: absolute;
  top: 10px;
  font-size: 0.7rem;
  transform: rotate(20deg);
  color: var(--red);
}