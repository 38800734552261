.timesheet {
  padding-bottom: 3rem;
}

.timesheet-header {
  display: flex;
  padding: 0.4rem 1rem;
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
}

.timesheet-title.title {
  flex: 2;
  align-items: center;
  line-height: 2.5rem;
  font-size: 1.4rem;
}

.timesheet-date {
  flex: 1;
  align-items: right;
}

.timesheet-title svg {
  margin-right: 10px;
} 

.timesheet-date-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.timesheet-date-wrapper__item {
  flex: 1;
  padding: 0.5rem 0;
  background: var(--yellow);
  text-align: center;
}

.timesheet-date-wrapper__item:first-child {
  margin-right: 10px;
}

.select-year {
  position: relative;
}

.timesheet-date-wrapper select {
  background: var(--yellow);
  font-size: 1.1rem;
}

.timesheets__icon {
  fill: var(--black);
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -6px;
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  
  border-left: 6px solid var(--black);
}

.arrow-left {
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -6px;
  width: 0; 
  height: 0; 
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent; 
  
  border-right: 6px solid var(--black); 
}