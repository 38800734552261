.edit-project {
  display: flex;
  flex-flow: row wrap;
  flex: 1 100%;
}

.edit-project form {
  padding: 0.5rem;
}

.edit-project label {
  line-height: 28px;
}

.edit-project input[type="text"] {
  width: 100%;
  background: var(--lightblue);
  border: none;
  padding: 0.5rem 1rem;
}

.edit-project-button {
  display: block;
  background: var(--orange);
  border: none; 
  padding: 0.3rem 0.5rem;
}