.week {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
  margin-bottom: 20px;
  padding: 0.2rem 1rem;
}

.week h2 {
  font-family: 'lobster';
}

.week:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.week-day {
  position: relative;
  display: inline-block;
  text-align: center;
}

.week-day--header {
  padding-right: 5px;
  border-bottom: 1px solid var(--orange);
}

.week-day--summary {
  width: 49px;
  padding: 0.3rem 0.2rem;
  font-size: 22px;
  text-align: center;
  background: var(--lightyellow);
  border: none;
}

.week-day--summary {
  background: none;
}

.week-day--summary__hours {
  font-size: 12px;
  background: var(--lightblue);
}

.week-day input {
  width: 50px;
  margin-right: 5px;
  padding: 0.5rem 0.2rem;
  font-size: 25px;
  text-align: center;
  background: var(--lightyellow);
  border: none;
}

.is-today input,
.is-today input::placeholder {
  color: var(--white);
  background: var(--orange);
}

.week-day--summary__button {
  border: none;
  background: transparent;
}

.week-day--summary__button:focus {
  outline: none;
}

.week-day--summary__button svg {
  fill: var(--blue);
  transition: fill .4s ease;
}

.week-day--summary__button:hover svg {
  fill: var(--lightblue);
}

/* TEMP styling for contracts */
.red-border {
  border: 1px solid red !important;
}

.blue-border {
  border: 1px solid blue !important;
}