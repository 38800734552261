.app-menu-wrapper {
  font-family: 'roboto';
  border-bottom: 1px #99ccff solid;
}

.app-menu {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  font-size: 1.1rem;
}

.app-menu li {
  display: inline;
  box-sizing: border-box;
  padding-bottom: 3px;
}

.app-menu li a {
  padding: 0 10px;
  text-decoration: none;
  color: #99ccff;
}

.app-menu li .selected {
  border-bottom: 3px solid #99ccff;
}