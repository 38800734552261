
.header-time {
  display: flex;
  max-width: 62px;
  font-weight: 700;
  text-align: center;
  padding: 0.3rem;
}

.header-date-item {
  flex: 1 1;
}

.header-date-day {
  font-size: 2.7rem;
  line-height: 2rem;
  font-family: 'lobster';
  padding: 0 0.5rem 0 0;
}

.header-date-month {
  display: block;
  font-family: 'lobster';
  font-size: 0.8rem;
  line-height: 0.7rem;
  letter-spacing: 1px;
}

.header-date-year {
  color: #99ccff;
}
