.footer {
  display: flex;
  flex-flow: row wrap;
}

.footer > section {
  flex: 0 0 100%;
  text-align: center;
}

.footer-dark-bg {
  padding: 0.8rem 0 0.4rem;
  background-image: linear-gradient(var(--gray), var(--darkgray)); 
  color: var(--white);
}

.footer-headline {
  font-family: 'Lobster', Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-bottom: 1.2rem;
}

.footer-social-links {
  padding-bottom: 1rem;
}

.footer-social-links a { 
  margin: 0 0.8rem;
}

.footer-social-links img {
  width: 22px;
}

.footer-legal a {
  margin: 0 1.5rem;
  color: var(--blue);
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.6rem;
  letter-spacing: 1px;
}

.footer-company {
  padding: 0.6rem 0;
  font-size: 0.6rem;
}

.footer-company p {
  margin: 0.2rem 0 0;
  letter-spacing: 1px;
}

.footer-company img {
  width: 55px;
}