.signin {
  text-align: center;
}

.signin a {
  color: var(--black);
  text-decoration: none;
}

.signin .signin-section {
  position: relative;
  padding: 0.5rem 0 0.8rem;
}

.signin-section.signin-yellow-bg {
  position: relative;
  background-image: linear-gradient(var(--lightyellow), var(--yellow));
  padding-bottom: 2rem;
}

.signin-yellow-bg-arrow-down {
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -15px;
  background: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 25px;
  box-shadow: 0 2px #ccc;
  font-family: 'Lobster', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 2.1rem;
  letter-spacing: 2px;
  padding-left: 3px;
}

p {
  padding: 0 1rem;
}

.signin input[type=text],
.signin input[type=password] {
  display: block;
  margin: 1rem auto;
  padding: 0.8rem 1rem;
  background: var(--white);
  border: none;
  text-align: center;
  transition: all 0.2s ease-in;
}

.signin .login--button {
  padding: 0.7rem 0;
  background: var(--yellow);
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 2px;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  min-width: 158px;
}

.signin .login--button:hover {
  background: var(--orange);
  color: var(--white);
  border-color: var(--white);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.signin .provider-login--facebook {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--facebook-blue);
  margin: 1.5rem 0 0.5rem;
}

.signin .provider-login--facebook:hover {
  background: var(--facebook-blue);
}

.signin .provider-login--google:hover {
  background: var(--google-red);
}

.signin .provider-login--github:hover {
  background: var(--github-blue);
}

.signin .provider-login--facebook:hover,
.signin .provider-login--google:hover,
.signin .provider-login--github:hover {
  opacity: 0.8;
}

.signin .provider-login--google {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--google-red);
  margin: 0.5rem 0;
}

.signin .provider-login--github {
  border: none;
  text-transform: none;
  color: var(--white);
  background: var(--github-blue);
  margin: 0.5rem 0;
}

.signin .password-forget {
  padding: 0.3rem;
  font-size: 0.7rem;
}

.create-account {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}